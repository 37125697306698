export const routes = {
  index: {
    label: 'Paintings',
    route: '/',
  },
  artist: {
    label: 'Artist',
    route: '/artist/',
  },
  exhibitions: {
    label: 'Exhibitions',
    route: '/exhibitions/',
  },
  galleries: {
    label: 'Galleries',
    route: '/galleries/',
  },
  contact: {
    label: 'Contact',
    route: '/contact/',
  },
}
