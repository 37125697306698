// modules
import React from 'react'
import { Link } from 'gatsby'
// styles
import styles from './Footer.module.scss'

// definition
function Footer() {
  return (
    <footer className={styles.Footer}>
      <Link
        to="/privacy/"
        className={styles.link}
        activeClassName={styles.active}
      >
        Privacy
      </Link>
    </footer>
  )
}

// exports
export default Footer
