// modules
import React from 'react'
import posed, { PoseGroup } from 'react-pose'
// styles
import styles from './Layout.module.scss'
// components
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import WidthContainer from '../components/shared/WidthContainer/WidthContainer'
// animations
import animations from './LayoutAnimations'
const FadeIn = posed.div(animations.fadeIn)
const RouteTransition = posed.div(animations.routeTransition)

// definitions
function Layout({ children, location }) {
  return (
    <FadeIn className={styles.Layout} pose="in" initialPose="out">
      <Header />
      <PoseGroup flipMove={false}>
        <RouteTransition
          className={styles.RouteTransition}
          key={location.pathname}
        >
          <main>
            <WidthContainer>{children}</WidthContainer>
          </main>
          <Footer />
        </RouteTransition>
      </PoseGroup>
    </FadeIn>
  )
}

// Export
export default Layout
