// modules
import React from 'react'
// styles
import styles from './WidthContainer.module.scss'

// definitions
function WidthContainer({ children }) {
  return <div className={styles.WidthContainer}>{children}</div>
}

// exports
export default WidthContainer
