// configurations
const animations = {
  // fadeIn
  fadeIn: {
    in: { opacity: 1, transition: { delay: 150, duration: 500 } },
    out: { opacity: 0 },
  },
  // routeTransition
  routeTransition: {
    enter: {
      opacity: 1,
      transition: { duration: 150, delay: 300 },
    },
    exit: {
      opacity: 0,
      transition: { duration: 150 },
    },
  },
}

// exports
export default animations
