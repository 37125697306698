// modules
import posed from 'react-pose'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'gatsby'
// styles
import styles from './Navbar.module.scss'
// routes
import { routes } from '../../../utils/routes'
// components
import BurgerButton from './BurgerButton/BurgerButton'
import SiteTitle from './SiteTitle/SiteTitle'
// animations
import { animations } from './NavbarAnimations'

// definitions
const Backdrop = posed.div(animations.Backdrop)
const CollapsedNavList = posed.ul(animations.CollapsedNavList)
const CollapsedNavListItem = posed.li(animations.CollapsedNavListItem)
const ExpandedNavList = posed.ul(animations.ExpandedNavList)
const ExpandedNavListItem = posed.li(animations.ExpandedNavListItem)

function Navbar({ collapsed }) {
  // state
  const [navIsVisible, setNavIsVisible] = useState(false)

  // handlers
  const toggleNav = () => setNavIsVisible(!navIsVisible)
  const hideNav = () => setNavIsVisible(false)

  if (collapsed) {
    // narrow screen navigation
    return (
      <nav className={styles.Navbar}>
        <SiteTitle clickHandler={hideNav} />
        <BurgerButton clickHandler={toggleNav} isActive={navIsVisible} />
        <Backdrop
          className={styles.Backdrop}
          pose={navIsVisible ? 'visible' : 'hidden'}
          initialPose="hidden"
        >
          <CollapsedNavList
            className={styles.CollapsedNavList}
            pose={navIsVisible ? 'visible' : 'hidden'}
          >
            {Object.keys(routes).map(key => (
              <CollapsedNavListItem key={key}>
                <Link
                  to={routes[key].route}
                  className={styles.link}
                  activeClassName={styles.active}
                  onClick={hideNav}
                >
                  {routes[key].label}
                </Link>
              </CollapsedNavListItem>
            ))}
          </CollapsedNavList>
        </Backdrop>
      </nav>
    )
  } else {
    // wide screen navigation
    return (
      <nav className={styles.Navbar}>
        <SiteTitle />
        <ExpandedNavList
          className={styles.ExpandedNavList}
          pose="visible"
          initialPose="hidden"
        >
          {Object.keys(routes).map(key => (
            <ExpandedNavListItem key={key} pose="visible" initialPose="hidden">
              <Link
                to={routes[key].route}
                className={styles.link}
                activeClassName={styles.active}
              >
                {routes[key].label}
              </Link>
            </ExpandedNavListItem>
          ))}
        </ExpandedNavList>
      </nav>
    )
  }
}

// props type checking
Navbar.propTypes = {
  collapsed: PropTypes.bool,
}

// exports
export default Navbar
