// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-artist-jsx": () => import("/opt/build/repo/src/pages/artist.jsx" /* webpackChunkName: "component---src-pages-artist-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-exhibitions-jsx": () => import("/opt/build/repo/src/pages/exhibitions.jsx" /* webpackChunkName: "component---src-pages-exhibitions-jsx" */),
  "component---src-pages-galleries-jsx": () => import("/opt/build/repo/src/pages/galleries.jsx" /* webpackChunkName: "component---src-pages-galleries-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("/opt/build/repo/src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */)
}

