// modules
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
// styles
import styles from './SiteTitle.module.scss'

// definitions
function SiteTitle({ clickHandler }) {
  return (
    <h2 className={styles.SiteTitle}>
      <Link to="/" onClick={clickHandler}>
        <span>Henri</span> Dulm
      </Link>
    </h2>
  )
}

// props type checking
SiteTitle.propTypes = {
  clickHandler: PropTypes.func,
}

// exports
export default SiteTitle
