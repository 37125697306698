// modules
import MediaQuery from 'react-responsive'
import React from 'react'
// styles
import breakpoints from '../../utils/_breakpoints.scss'
import styles from './Header.module.scss'
// components
import Navbar from './Navbar/Navbar'
import WidthContainer from '../shared/WidthContainer/WidthContainer'

// definitions
function Header() {
  return (
    <header className={styles.Header}>
      <WidthContainer>
        <MediaQuery minWidth={`${breakpoints.largeScreens}`}>
          {matches => {
            const screenIsNarrow = matches ? false : true
            return <Navbar collapsed={screenIsNarrow} />
          }}
        </MediaQuery>
      </WidthContainer>
    </header>
  )
}

// exports
export default Header
