// modules
import scroll from 'react-scroll'
// styles
import 'destyle.css'
import './src/utils/global-styles.scss'
// typefaces - self-hosted fonts
import 'typeface-eb-garamond'
import 'typeface-prata'

// methods
function shouldUpdateScroll({ prevRouterProps, routerProps }) {
  const previousRoute = prevRouterProps.location.pathname
  const nextRoute = routerProps.location.pathname
  const transitionTimeOut = 300

  if (previousRoute === nextRoute) {
    // smooth animated scroll to top if requested route is the current one
    scroll.animateScroll.scrollToTop({
      duration: 150,
      smooth: 'easeInOutCubic',
    })
  } else {
    // OR scroll to top after current route transitioned out
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, transitionTimeOut)
  }
  // prevent gatsby from handling scroll update
  return false
}

// exports
export { shouldUpdateScroll }
