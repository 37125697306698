export const animations = {
  // Collapsed Navigation List
  CollapsedNavList: {
    visible: {
      beforeChildren: true,
      staggerChildren: 50,
    },
    hidden: {
      afterChildren: true,
      staggerChildren: 50,
      staggerDirection: -1,
    },
  },
  // Collapsed Navigation List Item
  CollapsedNavListItem: {
    visible: {
      opacity: 1,
      y: '0%',
      transition: {
        y: { type: 'tween', ease: 'easeInOut', duration: 150 },
        opacity: { type: 'tween', ease: 'linear', duration: 150 },
      },
    },
    hidden: {
      opacity: 0,
      y: '100%',
      transition: {
        y: { type: 'tween', ease: 'easeInOut', duration: 150 },
        opacity: { type: 'tween', ease: 'linear', duration: 150 },
      },
    },
  },
  // Backdrop
  Backdrop: {
    visible: {
      applyAtStart: { display: 'flex' },
      opacity: 1,
      transition: { duration: 300 },
    },
    hidden: {
      afterChildren: true,
      applyAtEnd: { display: 'none' },
      opacity: 0,
      transition: { duration: 300 },
    },
  },
  // Expanded Navigation List
  ExpandedNavList: {
    visible: { beforeChildren: true, staggerChildren: 100 },
    hidden: {},
  },
  // Expanded Navigation List Item
  ExpandedNavListItem: {
    visible: {
      opacity: 1,
      y: '0%',
      transition: {
        y: { type: 'tween', ease: 'easeOut', duration: 300 },
        opacity: { type: 'tween', ease: 'linear', duration: 300 },
      },
    },
    hidden: {
      opacity: 0,
      y: '-100%',
      transition: {
        y: { type: 'tween', ease: 'easeIn', duration: 150 },
        opacity: { type: 'tween', ease: 'linear', duration: 150 },
      },
    },
  },
}
